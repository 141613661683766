import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageNotFound from "../modules/PageNotFound"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    {/* <div className="main_body_2" style={{border:"1px solid blue", backgroundColor:"#dcdcdc"}}>
<div className="middle_texts">
  aaaaaaaaaaaaaaaaaaaa
</div>
    </div> */}
    <PageNotFound/>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Layout>
)

export default NotFoundPage
