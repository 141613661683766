import React,{Component} from "react";
import Logo_new_2 from "../images/Logo_new_2.png"
import { Link } from "gatsby"
class PageNotFound extends React.Component{
  render(){
    return(
<>

  <div  className="main_body_2">
  <div className="middle_text">
  <div className="logo">
  <Link to="/">
  <img src={Logo_new_2} style={{width:"270px"}}></img>
  </Link>
  </div>
  <div className="align_text2">
  Page Not Found
    </div><br/>
  <div className="align_text">
  The requested page does not exist. We will try to automatically redirect you in 10 seconds.
    </div> 
    <br/>
    <div className="align_text">
    Please go to the Home page by clicking on the logo above.
    </div>
  </div>
  </div>
  
  </>
    );
  }

}
export default PageNotFound